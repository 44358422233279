
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  async mounted() {
    const to = useRoute();
    const next = useRouter().push;
    const { state } = to.query;
    if (state) {
      next({ name: 'New Integration', query: to.query, params: { subdomain: state.toString() } });
    } else {
      next({ name: 'Not Found', replace: true });
    }
  },
});
